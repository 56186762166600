$(document).ready(function()
{
    $('a:not(.accordion-title)').each(function (index, value) {
		
		if ($(this).prop('href').indexOf("#") > 0) {
    		// Captures click events of all <a> elements with href starting with #
    		$(this).click(function(event) {
    		  // Click events are captured before hashchanges. Timeout
    		  // causes offsetAnchor to be called after the page jump.
			  event.preventDefault();
	
			  var aid = $(this).attr("href");
  			  history.pushState({}, '', aid);

    		  window.setTimeout(function() {
				$([document.documentElement, document.body]).animate({
			        scrollTop: $(aid).offset().top - 200
				}, 1000);
    		  }, 50);
    		});
    	}
    
    	if (window.location.href.indexOf("iframe") > -1) {
    		$(this).attr('target','_blank');
    	}

        var testString = ['/','https://www.tds-rad','http://www.tds-rad', '#', 'https://cms.tds-rad', 'http://cms.tds-rad','https://www.tds-velo','http://www.tds-velo', '#', 'https://cms.tds-velo', 'http://cms.tds-velo'];

        var href = $(this).attr('href');
        var intern = false;

        if(typeof href !== 'undefined' && href !== null){
        	if (href.indexOf('.pdf') > -1) {
        		intern = false;
        	} else {
	            for ( var counter = 0; counter<testString.length;counter++)
	            {
	                if (href.trim().toLocaleString().indexOf(testString[counter])==0)       //should start with
	                {
	                    intern = true;
	                    break;
	                }
	            }
        	}
            if (!intern)
            {
                var targetAttr = $(this).attr('target');
                //do not overwrite existing targets
                if (!( typeof targetAttr !== typeof undefined && targetAttr !== false))
                {
                    // console.log('Set target for ' + href);
                    $(this).attr('target','_blank');
                    $(this).attr('rel','noreferrer');
                }
            }
        }

    });

});
