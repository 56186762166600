if(document.getElementById("haendlerfinden")){
	
	var haendlerfinden = new Vue({
	  el: '#haendlerfinden',
	  data: {		  
		  error: "",
		  searchLocation: "",
		  resellers: [],
		  howManyReseller: 4,
		  activeReseller: []
	  },
	  created: function () {

		  var that = this;
		  if(document.getElementById("reseller")){
			
			var a = $('#reseller').val().split(",")
			if (a[0] == '') {
				that.activeReseller = [];
			} else {
				that.activeReseller = a;
			}
	
		  }
		  fetch(window.magnoliaFrontendData.contextPath + '/.rest/reseller', {
			    credentials: 'include'
		  })
		  .then(function(response) {
		    return response.json();
		  })
		  .then(function(json) {
			  that.resellers = json.results;
		  });
		  
	  },
	  computed: {
	  	  filteredActiveLocations: function() {
			  var that = this;
			  
			  locations = that.resellers.filter(function (item) {
				if (that.activeReseller.indexOf(item['@id']) > -1) {
					return true;
				}
				if (that.activeReseller.indexOf(item.name + ", " + item.street+", " + item.plz + " " + item.city) > -1) {
					return true;
				}
			  });
			  
			  locations = locations.slice(0,that.howManyReseller);
			  
			  return locations;
		  },
		  filteredLocations: function() {
			  var that = this;
			  
			  locations = that.resellers.filter(function (item) {
				if (item.name) {
			      if (item.name.toLowerCase().indexOf(that.searchLocation.toLowerCase()) > -1) {
			      	return true;
			      }
				}
				if (item.kundennummer) {
			      if (item.kundennummer.toLowerCase().indexOf(that.searchLocation.toLowerCase()) > -1) {
			      	return true;
			      }
				}
				if (item.plz) {
			      if (item.plz.toLowerCase().indexOf(that.searchLocation.toLowerCase()) > -1) {
			      	return true;
			      }
				}
				if (item.city) {
			      if (item.city.toLowerCase().indexOf(that.searchLocation.toLowerCase()) > -1) {
			      	return true;
			      }
				}
			  });
			  
			  locations = locations.filter(function (item) {
				if (that.activeReseller.indexOf(item['@id']) == -1) {
					return true;
				}
			  });

			  return locations;
		  },
		  filteredLocationsSlice: function() {
		  	return this.filteredLocations.slice(0, this.howManyReseller)
		  }
	  },
	  methods: {
	    showMoreLocations: function(e) {
			  var that = this;
			  that.howManyReseller += 4;
		},
		addReseller: function(id) {
			this.activeReseller.push(id);
			$('#reseller').val(this.activeReseller.join());
		},
		removeReseller: function(id) {
			this.activeReseller = this.activeReseller.filter(function(ele){ 
	            return ele != id; 
	        });
			$('#reseller').val(this.activeReseller.join());
		} 
	  }  
	});
	
	String.prototype.splitPlus = function(sep) {
	  var a = this.split(sep)
	  if (a[0] == '') return [];
	  return a;
	};
};