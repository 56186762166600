document.querySelectorAll('.configSplash').forEach(item => {
  item.addEventListener('submit', function(event) {
    event.preventDefault();
	
	var parameters = {
		"configurationId": item.querySelector('input[name=configurationId]').value
	}

	fetch(window.magnoliaFrontendData.contextPath + '/.rest/commands/v2/tds/getConfiguration', {
		  	headers: {
		      'Accept': 'application/json',
		      'Content-Type': 'application/json',
		      'credentials': 'include'
		    },
		    method: 'post',
		    body: JSON.stringify(parameters)
		  })
	  .then(function(response) {
		  return response.json();
	})
	.then(function(json) {			
		  if (json.configuration) {
			  configurationURL = ""
			  errorMessage = "";
			  if (json.configuration.configuration) {
				  configurationURL = JSON.parse(json.configuration.configuration).bikeurl;
				  item.setAttribute("action", configurationURL);
				  var url = new URL(configurationURL);
				  item.querySelector('input[name=year]').value = url.searchParams.get("year");
				  item.querySelector('input[name=rahmenTyp]').value = url.searchParams.get("rahmenTyp");
				  item.submit()
			  } else {
				  configurationURL = "";
				  item.querySelector('input[name=year]').value = window.i18n.errormessage;
			  }
		  }
	});	
  });
});