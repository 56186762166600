//import '../sass/style';

import { Foundation } from 'foundation-sites';
import jquery from 'jquery';

window.jQuery = jquery;
window.$ = jquery;
Foundation.addToJquery($);

import customFileUpload from './customFileUpload.js';
import configTeaser from './configTeaser.js';
import resellersuche from './resellersuche.js';
import externallinks from './externallinks.js';

$(document).ready(function(){

	jQuery.event.special.touchstart = {
        setup: function( _, ns, handle ){
            this.addEventListener("touchstart", handle, { passive: true });
        }
    };
    
    $(".slick-slider").slick({

	  // normal options...
	  infinite: true,
      slidesToShow: 3,
      dots: false,
      prevArrow: '.slick-prev',
      nextArrow: '.slick-next',
	
	  // the magic
	  responsive: [{
	
	      breakpoint: 1024,
	      settings: {
	        slidesToShow: 3,
	        infinite: true
	      }
	
	    }, {
	
	      breakpoint: 600,
	      settings: {
	        slidesToShow: 1,
	      }
	
	    }, {
	
	      breakpoint: 300,
	      settings: "unslick" // destroys slick
	
	    }]
	});

    $('.new-back').each(function(){
      var backTxt = $(this).closest('.is-drilldown-submenu-parent').find('> a').text();
      $(this).text(backTxt);
    });
    $('.dropdown-pane:not(.no-overlay)').on('show.zf.dropdown', function(event) {
    	$('.grey-overlay').addClass('is-open');
    });
    $('.dropdown-pane:not(.no-overlay)').on('hide.zf.dropdown', function(event) {
    	$('.grey-overlay').removeClass('is-open');
    });    
    $('.dropdown-pane-close').on('click', function() {
    	$('.dropdown-pane').foundation('close');
    });
    
    
    $('#mobile-nav-toggle').on('click', function(event) {
    	$(this).toggleClass('open');
    	if ($('main').is(':visible')) {
    		$('main').hide();
    		$('footer').hide();
    	} else {
    		$('main').show();
    		$('footer').show();
    	}
    });
    
    $('#nav-desktop-drilldown').on('open.zf.drilldown', function(event) {
    	$(this).foundation('_hideAll');
    });
    
    // Floating Form Labels: https://foundation.zurb.com/building-blocks/blocks/floated-label-wrapper.html
    var showClass = 'show';

    $('input[type=text],input[type=email], textarea').on('checkval', function () {
      var label = $(this).prev('label');
      if(this.value !== '') {
        label.addClass(showClass);
      } else {
        label.removeClass(showClass);
      }
    }).on('keyup', function () {
      $(this).trigger('checkval');
    });

    if(document.getElementById("bikesearch")){
	    window.onscroll = function() {
	    	var scrollTop = (window.pageYOffset !== undefined) ? window.pageYOffset : (document.documentElement || document.body.parentNode || document.body).scrollTop;
	    	
	    	if(scrollTop >= document.querySelector('#bikesearch').offsetTop) {
	    		document.querySelector('.sticky').classList.add('shadow');
	    	} else {
	    		document.querySelector('.sticky').classList.remove('shadow');
	    	}
	    };	    
    }
	
	$("input[name=email]").keyup(function() {
		$("input[name=username]").val($(this).val())
	});
	
	$("input[name=firstName]").keyup(function() {
		$("input[name=fullName]").val($(this).val() + " " + $("input[name=lastName]").val())
	});
	
	$("input[name=lastName]").keyup(function() {
		$("input[name=fullName]").val($("input[name=firstName]").val() + " " + $(this).val())
	});
	
	if(document.getElementById("locationfinder")){
		import(/* webpackChunkName: "locationfinder" */'./locationfinder/004.locationfinder.js').then(module => {
			$(document).foundation();
		});
	} else if(document.getElementById("configurator") && (window.magnoliaFrontendData.year == "2023" || window.magnoliaFrontendData.year == "2024" || window.magnoliaFrontendData.year == "2025")){
		if (window.magnoliaFrontendData.currentLanguage == "de-CH") {
			import(/* webpackChunkName: "i18n" */'./i18n/de.js')
		} else {
			import(/* webpackChunkName: "i18n" */'./i18n/fr.js')
		}
		import(/* webpackChunkName: "configurator2023" */'./configurator2023/003.configurator.js').then(module => {
			
			$("#resellerFilter").on("keyup", function() {
			    var value = $(this).val().toLowerCase();
			    $("#resellerList .reseller").filter(function() {
			      $(this).toggle($(this).text().toLowerCase().indexOf(value) > -1)
			    });
			  });
			  $("#showAllReseller").click(function() {
			  	$("#resellerList .reseller").toggle();
			  	$("#resellerFilter").val("");
			})
			
			$(document).foundation();
		});
		import(/* webpackChunkName: "video" */'./configurator2023/video.js')
		import(/* webpackChunkName: "reseller" */'./configurator2023/reseller.js')
		import(/* webpackChunkName: "nachfolger" */'./configurator2023/nachfolger.js')
		import(/* webpackChunkName: "availability" */'./configurator2023/product-availability.js')
		import(/* webpackChunkName: "options" */'./configurator2023/options-availability.js')
		import(/* webpackChunkName: "latest" */'./configurator2023/latest-availability.js')
		import(/* webpackChunkName: "util" */'./configurator2023/util.js')
	} else if(document.getElementById("merkliste")){
		import(/* webpackChunkName: "merkliste" */'./merkliste.js').then(module => {
			$(document).foundation();
		});
	} else {
		$(document).foundation();
		window.initFilters();
	}


});

window.initFilters = function() {
	
    $('.filterBtn').on('click', function() {
    	if ($('.filters').is(':visible')) {
    		$('.filters').hide();
    		$('.appliedFilters').hide();
    		$('.filterBtnClose').hide();
    		$('.filterBtn').css('display', 'inline-block');
    		if (Foundation.MediaQuery.is('small only')) {	
    			$('.sticky').css('height', 'auto');	
    		} 
    	} else {
    		$('.filters').show();
    		$('.appliedFilters').show();
    		$('.filterBtnClose').css('display', 'inline-block'); 
    		$('.filterBtn').hide();   
    		if (Foundation.MediaQuery.is('small only')) {	
    			$('.sticky').css('height', '100vh');	
    		}
    	}
    });
    
    $('.filterBtnClose').on('click', function() {
    	if ($('.filters').is(':visible')) {
    		$('.filters').hide();
    		$('.appliedFilters').hide();
    		$('.filterBtnClose').hide();
    		$('.filterBtn').css('display', 'inline-block');
    		if (Foundation.MediaQuery.is('small only')) {	
    			$('.sticky').css('height', 'auto');	
    		} 
    	} else {
    		$('.filters').show();
    		$('.appliedFilters').show();
    		$('.filterBtnClose').css('display', 'inline-block'); 
    		$('.filterBtn').hide();   
    		if (Foundation.MediaQuery.is('small only')) {	
    			$('.sticky').css('height', '100vh');	
    		}
    	}
    });
}

window.activeFilters = new Map();;
window.setFilter = function(contentid, subcategory, category) {
	
	var actCat = window.activeFilters.get(category);
	
	if (actCat == undefined) {
		actCat = [];
	}
	
	if (actCat.includes(subcategory)) {
		actCat = actCat.filter(item => item !== subcategory)
		window.activeFilters.set(category, actCat);
		$('#remove-'+subcategory).hide();
	} else {
		actCat.push(subcategory);
		window.activeFilters.set(category, actCat);
		$('#remove-'+subcategory).show();		
		$(".appliedFilters").show();
	}
	
	if (window.activeFilters.length == 0) {
		$(".bs-"+contentid+" .bike").show();
		return	
	}
	
	for (var [key, value] of window.activeFilters.entries()) {
		if (value.length == 0) {
			window.activeFilters.delete(key)
		}
	}

	$(".bs-"+contentid+" .bike").hide();

    $(".bs-"+contentid+" .bike").filter(function() {
    	var bike = $(this);
    	var filterCheck = 0;
		var gesamtfilter= 0;
    	
    	for (var [key, value] of window.activeFilters.entries()) {
	    	value.forEach(function(item, index) {
				gesamtfilter++;
	    		if (bike.data("filter").indexOf(item) > -1) {
	    			filterCheck++;
	    		}
	    	});
    	}
    	
    	if (gesamtfilter == filterCheck) {
    		bike.show();
    	}
    	
    });
    
};

window.removeFilter = function(contentid, subcategory, category) {
    document.getElementById('id-'+subcategory).checked = false;
    window.setFilter(contentid, subcategory, category)
};

//Set the offset when entering page with hash present in the url
window.onload = function() {	
	if (location.hash.length !== 0) {
	window.setTimeout(function() {
		window.scrollTo(window.pageXOffset, window.pageYOffset - 200); 
    }, 50);
	}
}

window.validateEmail = function(email) {
  return new RegExp(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/).test(email)
};


